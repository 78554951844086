import React from 'react'
import './brand.css'
import {google,react,atlassian,dropbox,shopify} from './imports'
import { motion, useAnimation } from "framer-motion";

import { useInView } from "react-intersection-observer";

import { useEffect } from "react";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 0 }
};

const Brand = ({num}) => {

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
      console.log('true');
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <motion.div
      variants={boxVariant}
      ref={ref}
      initial="hidden"
      animate={control}
      className="gpt3__brand section__padding">
      <div>
        <img src={google} alt="google" />
      </div>
      <div>
        <img src={react} alt="react" />
      </div>
      <div>
        <img src={atlassian} alt="atlassian" />
      </div>
      <div>
        <img src={dropbox} alt="dropbox" />
      </div>
      <div>
        <img src={shopify} alt="shopify" />
      </div>
    </motion.div>
  )
}

export default Brand