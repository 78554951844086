import React from 'react';
//import people from '../../assets/people.png';
import ai from '../../assets/neotech-header.png';
import './header.css';
import {motion} from 'framer-motion';

const Header = () => (
  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <motion.h1 animate={{}} transition={{ ease: "anticipate", duration: 1 }} className="gradient__text ">Gjennom digital 
markedsføring leverer vi vellykkede nettløsninger.</motion.h1>
      <motion.p animate={{y:2}} transition={{ ease: "anticipate", duration: 1 }}>Vi tilbyr å levere utmerkede, innovative og de beste produktene til virksomheten din for å tiltrekke flere besøkende digitalt for å utvide virksomheten din.</motion.p>

      <div className="gpt3__header-content__input">
        <a href="#kontakt">
          <motion.button
          whileHover= {{scale: 1.1}}
          whileTap ={{scale: 0.9}}
            type="button">Kontakt
            Oss</motion.button>
        </a>
      </div>

      {/* <div className="gpt3__header-content__people">
        <img src={people} />
        <p>1,600 people requested access a visit in last 24 hours</p>
      </div> */}
    </div>

    <motion.div
      animate={{ x: 100 }}
  transition={{ ease: "easeOut", duration: 2 }}
      className="gpt3__header-image">
      <img src={ai} alt="NeoTech Solutions" />
    </motion.div>
  </div>
);

export default Header;