import React, { useRef, useState, useEffect } from 'react';
import { ThreeCircles } from 'react-loader-spinner'
import './form.css'
import emailjs from '@emailjs/browser';

const Form = () => {
  const form = useRef();
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    }, 1000)
  },[])

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_t8ylk66', 'template_fk5rdri', form.current, '-MsX9VbDSnwPyERQt')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };
  return (
    <>
   <form onSubmit={sendEmail} ref={form}>
   <div className="flex">
     <div className="input-container ic1">
        <input
          id="fullname"
          className="input"
          type="text"
          name='from_name'
          placeholder=' '
          required
          />
        <label for="fullname" className="placeholder">FULT NAVN / FIRMA</label>
      </div>
      <div className="input-container ic2">
        <input
          id="email"
          className="input"
          type="email"
          placeholder=" "
          name='email'
          required
          />
        <label htmlFor="email" className="placeholder">E-POST</label>
      </div>
      <div className="input-container ic2">
        <input
          id="tel"
          className="input"
          placeholder=" "
          type="text"
          name='tel'
          required
          />
        <label htmlFor="tel" className="placeholder">TELEFON</ label>
      </div>
      <div className="input-container ic2">
        <textarea type="text" name="message" className="input" placeholder=" " cols="40" rows="30"></textarea>
        <label htmlFor="message" className="placeholder">Melding</ label>
      </div>
  <ThreeCircles
        height="50"
        width="50"
        color="#ffffff"
        wrapperStyle={{}}
        wrapperClass="padding-top"
        visible={false}
        ariaLabel="three-circles-rotating"
        outerCircleColor=""
        innerCircleColor=""
        middleCircleColor=""
      />
      
      <button type="submit" onSubmit={setLoading}  className="submit">Send</button>


    </div> 
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2000.153470593414!2d10.761875416095972!3d59.91300028186687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416e5fba9c803f%3A0x6ea8c4691e77f75c!2sT%C3%B8yengata%202%2C%200190%20Oslo!5e0!3m2!1sen!2sno!4v1653483863364!5m2!1sen!2sno" width="100%" height="500px" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </form> 
    </>
  )
}

export default Form