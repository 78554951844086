import React, {useState} from 'react'
import { RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import logo from '../../assets/neoTech_logo_white.png';
import './navbar.css'

const Menu = () =>(
  <>
   <p><a href="#home">Hjem</a></p>
    <p><a href="#om">Om Oss</a></p>
    <p><a href="#prosesser">Prosesser</a></p>
    <p><a href="#mision">Misjon og Visjon</a></p>

  </>
)


// BEM -> Block Element Modifier
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <img src={logo} alt="neotech" className="logo heartbeat" />
        </div>
        <div className="gpt3__navbar-links_container">
          <Menu />
        </div>
        </div>
        <div className="gpt3__navbar-sign">
          {/* <p>Sign in</p> */}
          <a href="#kontakt">
            <button type="button"> Kontakt Oss</button>
          </a>
        </div>

        <div className="gpt3__navbar-menu">
          {toggleMenu ? <RiCloseLine color= "#fff" size= {27} onClick = { () => setToggleMenu(false)} />
          :
          <RiMenu3Line color= "#fff" size= {27} onClick = { () => setToggleMenu(true)}
          />}
          {toggleMenu && (
            <div className="gpt3__navbar-menu_container scale-up-center">
              <div className="gpt3__navbar-menu_container-links">
                 <Menu />
                 <div className="gpt3__navbar-menu_container-link-sign">
                  {/* <p>Sign in</p> */}
                  <a href="#kontakt">
                    <button type="button"> Kontakt Oss</button>
                  </a>
                </div>  
              </div>
            </div>
          )}
        </div>
      </div>
  )
}

export default Navbar