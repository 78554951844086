import React from 'react'
import possibilityImage from '../../assets/vision.png'
import './possibility.css'

const Possibility = () => {
  return (
    <div className="gpt3__possibility section__padding" id="mision">
      <div className="gpt3__possibility-image" id="bounce">
        <img src={possibilityImage} alt="NeoTech Solutions" />
      </div>
      <div className="gpt3__possibility-content">
        <h4>“It’s hard to find things that won’t sell online.” – Jeff Bezos</h4>
        <h1 className="gradient__text">Vår Misjon og Visjon</h1>
<p>Vår misjon er å jobbe med kunder for å hjelpe dem med å lykkes når vi bygger en variert kundebase av bedrifter og enkeltpersoner fra ulike bransjer og økonomier.</p>
<p>Vår visjon er å bli et toppbyrå i den internasjonale forretningsverdenen, som tilbyr online markedsføringsstrategier, digitale salgsløsninger og internettmerkestyring.</p>
      </div>
    </div>
  )
}

export default Possibility