import React from 'react';
import logo from '../../assets/neoTech_logo_white.png';
import { RiFacebookFill, RiInstagramFill} from 'react-icons/ri';
import './footer.css';
import { Form} from '../../components'

const Footer = () => (
  <div className="gpt3__footer section__padding" id="kontakt">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">La oss utvide virksomheten din digitalt</h1>
    </div>

    <div className="gpt3__footer-btn">
      <p>Kontakt Oss</p>
    </div>

    <div className="fullwidth">
      <Form />
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={logo} alt="NeoTech Logo" />
      </div>
      {/* <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p>Overons</p>
        <p>Social Media</p>
        <p>Counters</p>
        <p>Contact</p>
      </div> */}
      <div className="gpt3__footer-links_div">
        <h4>Tjenester</h4>
        <p>Webløsninger</p>
        <p>Digital rådgiving </p>
        <p>Grafisk design</p>
        <p>Søkemotoroptimalisering</p>
        <p>Sosiale medier</p>
        <p>Google annonser</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Kontakt Oss</h4>
        <p>Tøyengate 2, 0190 Oslo</p>
        <p>+47 483 57 777</p>
        <p>kontakt@neotech.no</p>
        <div>
          <a href="https://www.facebook.com/neotechnorge" target="_blank" rel="noreferrer" ><RiFacebookFill style={{ color: 'white', size: '50px' }} /></a>
          <a href="https://www.instagram.com/neotech.no/" target="_blank" rel="noreferrer" ><RiInstagramFill style={{ color: 'white', size: '50px' }} /></a>
        </div>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2022 NeoTech. All rights reserved.</p>
    </div>
  </div>
);

export default Footer;