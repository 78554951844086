import React from 'react'

import {Footer, Possibility, Features, WhatGPT3, Header} from './containers';
import { CTA, Brand, Navbar} from './components'
import './App.css'

const App = () => {
  return (
    <div className="A[[">
        <div className="gradient__bg">
          <Navbar />
          <Header />
        </div>
        <Brand />
        <WhatGPT3 />
        <Features />
        <Possibility />
        <CTA />
        <Footer />
    </div>
  )
}

export default App