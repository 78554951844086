import React from 'react';
import './cta.css';

const CTA = () => (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <p>Kontakt oss for å komme i gang</p>
      <h3>Vi er et kreativt byrå med lidenskap for design.</h3>
    </div>
    <div className="gpt3__cta-btn">
      <a href="#kontakt"><button type="button">Kontakt Oss</button></a>
    </div>
  </div>
);

export default CTA;