import React from 'react'
import { Feature } from '../../components'
import './features.css'

const featuresData = [
  {
    title: 'Møte',
    text: 'Vi møter våre kunder for å diskutere deres behov og diskutere ideer sammen for å nå målene.'
  },
  {
    title: 'Planlegger',
    text: 'Vi vil deretter utforske passende moderne teknikker, kampanjer og markedsføringsstrategier, for å designe/promovere nettstedet ditt for å nå et stort publikum.'
  },
  {
    title: 'Design / Implementere',
    text: 'Det neste trinnet vil bli tatt i henhold til den angitte pakken som kan inkludere nettstedsoppretting, blogg- og innholdsutvikling, kampanjer på sosiale medier, eventadministrasjon og kampanjer og så videre.'
  },
  {
    title: 'Følge opp',
    text: 'Konstant oppfølging vil bli gjort for å analysere fremdriften i arbeidet vårt og effektive strategier vil bli implementert for å heve virksomheten din ytterligere.'
  },
]

const Features = () => {
  return (
    <div className="gpt3__features section__padding" id="prosesser">
      <div className="gpt3__features-heading">
        <h1 className="gradient__text">Vi hjelper bedrifter med å skape digitale opplevelser, nå ut til kunder og vokse. Vi elsker det vi gjør.</h1>
        <p>Vår Prosesser</p>
      </div>
      <div className="gpt3__features-container">
        {featuresData.map((item,index) =>(
          <Feature title={item.title} text={item.text} key={item.title +index} />
        ))}
      </div>
    </div>
  )
}

export default Features