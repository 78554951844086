import React from 'react'
import { Feature } from '../../components'
import './whatgpt3.css'

const WhatGPT3 = () => {
  return (
  <div className="gpt3__whatgpt3 section__margin" id="om">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="OM NeoTech" text="Vi planlegger, skaper, måler, optimaliserer digitale og programvareløsninger. Vi 
bruker målrettede, datadrevne beslutninger for å lede trafikk til nettstedet ditt 
gjennom SEO og optimalisering. Med dette sikrer vi at kundene havner hos deg, 
og ikke hos dine konkurrenter." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">Vi er et Kreativt Byrå</h1>
      <p>Våre Tjenester</p>
    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Webdesign" text="Vi 
utvikler raske og effektive nettløsninger, som er designet med målgruppen dine i 
tankene." />
      <Feature title="System Utvikling" text="Våre programvare 
spesialister er eksperter i bygging av komplekse systemløsninger i henhold til dine krav. 
Vår designprosess sikrer at systemet vi skal utvikles passer riktig til målgruppen din." />
      <Feature title="Grafisk Design" text="Godt design ser ikke bare bra ut, men det formidler også et budskap, gjennom farger og 
design. Å skille seg ut fra konkurrentene er å ha en tidsriktig bilde av din bedrift" />
      <Feature title="Nettbutikk" text="Vi lager nettbutikker basert på din målgruppe. Vi tilbyr i tillegg de viktigste betalingsløsningene, blant annet Visa, MasterCard, Paypal, Stripe, Klarna, Vipps osv." />

       <Feature title="Salgskurs" text="Vi tilbyr salgskurs som hjelper bedrifter og personer med å nå sine mål og budsjetter. 
Ønsker du salgsstrategi som kan øke salget og skape motiverte medarbeidere? 
Ta kontakt!" />
    </div>
  </div>

  )
}

export default WhatGPT3